import React from 'react';
import '../../../styles/components/case-templates/multimedia-gallery/multimedia-gallery.scss';
import Slider from 'react-slick';
import _ from 'lodash';
import Img from 'gatsby-image';
import '../../../styles/utils/general.scss';
import {isSmallScreen, isLandscape} from '../../../utils/helpers';
import OracleMultimediaGallery from './OracleMultimediaGallery';
import gsap from 'gsap';

class MultimediaGallery extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      activeSlide: 0,
      prevSlide: null,
    };

    this.nextSlideBackground = this.nextSlideBackground.bind (this);
    this.previousSlideBackground = this.previousSlideBackground.bind (this);

    this.nextSlideForeground = this.nextSlideForeground.bind (this);
    this.previousSlideForeground = this.previousSlideForeground.bind (this);

    this.videoElement = null;
    this.previousVideoElement = null;
    this.videoElementOracle = null;
    this.imageGalleryWidth = null;
  }

  handleNextClick () {
    this.nextSlideBackground ();
    if (this.previousVideoElement) this.previousVideoElement.pause ();

    setTimeout (() => {
      this.nextSlideForeground ();
      //if (this.videoElement) this.videoElement.play ();
    }, 250);
  }

  handlePreviousClick () {
    this.previousSlideBackground ();
    if (this.previousVideoElement) this.previousVideoElement.pause ();

    setTimeout (() => {
      this.previousSlideForeground ();
    }, 200);
  }

  nextSlideBackground () {
    this.sliderBackground.slickNext ();
  }

  previousSlideBackground () {
    this.sliderBackground.slickPrev ();
  }

  nextSlideForeground () {
    this.sliderForeground.slickNext ();
  }

  previousSlideForeground () {
    this.sliderForeground.slickPrev ();
  }

  render () {
    var mediaWidth, dimensionsToMoveOracle;

    var galleryImageContainer =
      typeof window !== `undefined` &&
      document.getElementById ('gallery-image-wrapper');

    var galleryVideoContainer =
      typeof window !== `undefined` &&
      document.getElementById ('multimedia-gallery-video-wrapper');

    this.videoElement =
      typeof window !== `undefined` &&
      document.getElementsByClassName ('video')[this.state.activeSlide];

    this.videoElementOracle =
      typeof window !== `undefined` &&
      document.getElementsByClassName ('multimedia-gallery-oracle-video')[
        this.state.activeSlide
      ];

    this.previousVideoElement =
      typeof window !== `undefined` &&
      document.getElementsByClassName ('video')[this.state.prevSlide];

    var galleryWidthImageContainer =
      galleryImageContainer && galleryImageContainer.clientWidth;

    var galleryWidthVideoContainer =
      galleryVideoContainer && galleryVideoContainer.clientWidth;

    this.galleryImage =
      typeof window !== `undefined` &&
      document.getElementsByClassName ('gallery-image')[
        this.state.activeSlide + 1
      ];

    this.galleryImageTag =
      this.galleryImage &&
      this.galleryImage
        .getElementsByTagName ('picture')[0]
        .getElementsByTagName ('img')[0];

    function videoDimensions (video) {
      // Ratio of the video's intrisic dimensions
      var videoRatio = video.videoWidth / video.videoHeight;
      // The width and height of the video element
      var width = video.offsetWidth, height = video.offsetHeight;
      // The ratio of the element's width to its height
      var elementRatio = width / height;
      // If the video element is short and wide
      if (elementRatio > videoRatio) width = height * videoRatio;
      else
        // It must be tall and thin, or exactly equal to the original ratio
        height = width / videoRatio;
      return {
        width: width,
        height: height,
      };
    }

    var dimensions = this.videoElement && videoDimensions (this.videoElement);

    var oracleHideAnimation = gsap
      .to (this.videoElementOracle, {
        duration: 2.5,
        ease: 'power1.in',
        xPercent: 110,
      })
      .reversed (true);

    if (this.videoElement) {
      this.videoElement.addEventListener ('play', () =>
        oracleHideAnimation.reversed (false)
      );
      this.videoElement.addEventListener ('pause', () =>
        oracleHideAnimation.reversed (true)
      );
      this.videoElement.addEventListener ('ended', () =>
        oracleHideAnimation.reversed (true)
      );
    }

    const contentForSlideShow = _.map (this.props.data, d => {
      if (d.type === 'photo' && this.galleryImageTag) {
        mediaWidth = this.galleryImageTag.clientWidth;
      }

      if (d.type === 'photo') {
        if (galleryWidthImageContainer > mediaWidth)
          dimensionsToMoveOracle =
            (galleryWidthImageContainer - mediaWidth) / 2;
      } else {
        if (dimensions)
          dimensionsToMoveOracle =
            galleryWidthVideoContainer - dimensions.width;
      }

      return d.type === 'photo' && d.linkPhoto
        ? <div id="gallery-image-wrapper">
            <div
              className="gallery-image-inner-wrapper"
              style={{
                position: 'relative',
              }}
            >
              <Img
                key={d.linkPhoto.publicURL}
                fluid={d.linkPhoto.childImageSharp.fluid}
                className="gallery-image"
              />
              <OracleMultimediaGallery
                dimensionsToMoveOracle={dimensionsToMoveOracle}
                textFirstLine={d.oracleMessage.firstLine}
                textSecondLine={d.oracleMessage.secondLine}
                mediaType={d.type}
              />
            </div>
          </div>
        : <div id="multimedia-gallery-video-wrapper" key={d.videoId}>
            <div
              className="gallery-video-inner-wrapper"
              style={{position: 'relative'}}
            >
              <video
                className="video"
                controls
                preload="auto"
                style={{width: `100%`, height: `100%`}}
              >
                <source src={d.videoId.publicURL} type="video/mp4" />
              </video>
              <OracleMultimediaGallery
                dimensionsToMoveOracle={dimensionsToMoveOracle}
                textFirstLine={d.oracleMessage.firstLine}
                textSecondLine={d.oracleMessage.secondLine}
                mediaType={d.type}
              />
            </div>
          </div>;
    });

    /*
if (this.hasAttribute("controls")) {
			this.removeAttribute("controls")
		} else {
			this.setAttribute("controls", "controls")
*/

    const contentForSlideShowBackground = _.map (
      this.props.data,
      d =>
        d.sliderBgImage &&
        <Img
          key={d.sliderBgImage.publicURL}
          fluid={d.sliderBgImage.childImageSharp.fluid}
          fadeIn={false}
        />
    );

    var settingsBack = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      style: {
        opacity: 0.15,
      },
      arrows: false,
      dots: false,
    };

    var settingsFront = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      style: {
        height: '100%',
      },
      dots: true,
      beforeChange: (current, next) => {
        this.setState ({activeSlide: next, prevSlide: current});
      },
      arrows: false,
      appendDots: dots => {
        return (
          <div>
            {!isSmallScreen ()
              ? <div
                  className="navigation-box-multimedia-gallery"
                  style={{
                    borderRadius: '10px',
                    backgroundColor: 'transparent',
                    display: 'flex',
                  }}
                >
                  <button
                    type="button"
                    className="arrow-button"
                    onClick={() => this.handlePreviousClick ()}
                  >
                    <svg width="50px" height="50px" fill="white">
                      <path
                        transform="translate(1.000000, 1.000000)"
                        d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
                        id="path-1"
                      />
                      <polygon
                        transform="translate(13.000000, 14.000000)"
                        id="path-3"
                        points="15.2777778 1.22222222 5.5 11 15.2777778 20.7777778 16.6020545 19.453501 8.14855348 11 16.6020545 2.54649896"
                      />
                    </svg>
                  </button>
                  <ul
                    style={{
                      marginRight: '23px',
                      marginLeft: '23px',
                      marginTop: '0',
                      marginBottom: '0',
                      height: '50px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {dots}
                  </ul>

                  <button
                    type="button"
                    className="arrow-button"
                    onClick={() => this.handleNextClick ()}
                  >
                    <svg width="50px" height="50px" fill="white">
                      <path
                        transform="translate(1.000000, 1.000000)"
                        d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
                        id="path-1"
                      />
                      <polygon
                        transform="translate(15.000000, 14.000000)"
                        id="path-3"
                        points="6.82427674 1.22222222 16.6020545 11 6.82427674 20.7777778 5.5 19.453501 13.953501 11 5.5 2.54649896"
                      />
                    </svg>
                  </button>
                </div>
              : <div className="navigation-box-multimedia-gallery">
                  <ul
                    style={{
                      marginRight: '0',
                      marginLeft: '0',
                      marginTop: '0',
                      marginBottom: '0',
                    }}
                  >
                    {dots}
                  </ul>
                  <div className="button-div_mobile">
                    <button
                      type="button"
                      className="arrow-button"
                      onClick={() => this.handlePreviousClick ()}
                    >
                      <svg width="50px" height="50px" fill="white">
                        <path
                          transform="translate(1.000000, 1.000000)"
                          d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
                          id="path-1"
                        />
                        <polygon
                          transform="translate(13.000000, 14.000000)"
                          id="path-3"
                          points="15.2777778 1.22222222 5.5 11 15.2777778 20.7777778 16.6020545 19.453501 8.14855348 11 16.6020545 2.54649896"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="arrow-button"
                      onClick={() => this.handleNextClick ()}
                    >
                      <svg width="50px" height="50px" fill="white">
                        <path
                          transform="translate(1.000000, 1.000000)"
                          d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
                          id="path-1"
                        />
                        <polygon
                          transform="translate(15.000000, 14.000000)"
                          id="path-3"
                          points="6.82427674 1.22222222 16.6020545 11 6.82427674 20.7777778 5.5 19.453501 13.953501 11 5.5 2.54649896"
                        />
                      </svg>
                    </button>
                  </div>

                </div>}
          </div>
        );
      },
    };

    return (
      <div
        className="multimedia-gallery-wrapper"
        style={
          this.props.data && {
            marginTop: this.props.paddingTop,
            marginBottom: this.props.paddingBottom,
            height: isSmallScreen () && !isLandscape ()
              ? '700px'
              : isLandscape () ? '750px' : '800px',
          }
        }
      >
        {this.props.data &&
          <div className="picture-frame-container">
            <div className="picture-frame-background">
              {contentForSlideShowBackground &&
                <Slider
                  {...settingsBack}
                  ref={b => (this.sliderBackground = b)}
                >
                  {contentForSlideShowBackground}
                </Slider>}
            </div>
            <div className="picture-frame-foreground">
              {contentForSlideShow &&
                <Slider
                  {...settingsFront}
                  ref={c => (this.sliderForeground = c)}
                >
                  {contentForSlideShow}

                </Slider>}

            </div>
          </div>}
      </div>
    );
  }
}
export default MultimediaGallery;

//style={{right: dimensionsToMoveOracle}}
