import {graphql} from 'gatsby';
import React, {useState, useEffect} from 'react';
import AdvantagesBlock from '../components/CaseTemplates/AdvantagesBlock';
import Carousel360 from '../components/CaseTemplates/Carousel360';
import CaseClassification from '../components/CaseTemplates/CaseClassification';
import CaseFooter from '../components/CaseTemplates/CaseFooter';
import MainHighlight from '../components/CaseTemplates/MainHighlight';
import SimpleTextBlock from '../components/CaseTemplates/SimpleTextBlock';
import Layout from '../components/layout';
import KrPanoInteractionLogo from '../components/Pano/KrPanoInteractionLogo';
import PanoBanner from '../components/Pano/PanoBanner';
import ScrollInteractionLogo from '../components/Pano/ScrollInteractionLogo';
import SEO from '../components/SEO/SEO';
import '../styles/templates/case.scss';
import '../styles/utils/general.scss';
import website from '../utils/site-config';
import BannersSkin from '../components/CaseTemplates/BannersSkin';
import LogoMenu from '../components/CaseTemplates/LogoMenu';
import {makeScrollWaypoint} from '../utils/helpers';
import {makeScrollWaypointBottom} from '../utils/helpers';
import Testimonial from '../components/CaseTemplates/Testimonial';
import AuthUserContext from '../components/Session/AuthUserContext';
import WithAuthentication from '../components/Session/WithAuthentication';
import MultimediaGallery
  from '../components/CaseTemplates/MultimediaGallery/MultimediaGallery';
import StaticBanner from '../components/CaseTemplates/StaticBanner';
import PanoCarousel360 from '../components/CaseTemplates/PanoCarousel360';
import {isTouchDevice} from '../utils/helpers';
import ReactGA from 'react-ga';

const TemplateA = ({
  pageContext: {locale, casesArrayForPage, previousPathForPage},
  data,
  location,
}) => {
  return (
    <AuthUserContext.Consumer>
      {authUser =>
        !authUser && data.file.childMarkdownRemark.frontmatter.public === false
          ? <PrivateCaseNoLogin data={data} locale={locale} />
          : <CaseA
              locale={locale}
              casesArrayForPage={casesArrayForPage}
              previousPathForPage={previousPathForPage}
              data={data}
              location={location}
            />}
    </AuthUserContext.Consumer>
  );
};

export default WithAuthentication (TemplateA);

class PrivateCaseNoLogin extends React.Component {
  componentDidMount () {
    var event = new CustomEvent ('requestLogin');
    // Dispatch the event
    window.dispatchEvent (event);
  }

  render () {
    return (
      <Layout
        path={this.props.data.file.childMarkdownRemark.frontmatter.path}
        locale={this.props.locale}
        headerLogoColor={'#0033a0'}
      />
    );
  }
}

const CaseA = ({
  locale,
  casesArrayForPage,
  previousPathForPage,
  data,
  location,
}) => {
  const [panoInteraction, setPanoInteraction] = useState (false);
  const [scrollInteraction, setScrollInteraction] = useState (false);
  const [headerLogoColor, setHeaderLogoColor] = useState ('white');
  const [sideMenuLogoIsHidden, setSideMenuLogoIsHidden] = useState (true);
  const [sideMenuLogoColor, setSideMenuLogoColor] = useState ('white');
  const [hasReadTillEnd, setHasReadTillEnd] = useState (false);
  var logoSideMenuIsHidden = sideMenuLogoIsHidden === true ? 'is-hidden' : '';

  const fileFrontmatter = data.file.childMarkdownRemark.frontmatter;
  var previousPath = previousPathForPage;
  var currentCasesArray = casesArrayForPage;

  if (location.state) {
    previousPath = location.state.previousPath;
    previousPathForPage = location.state.previousPath;
  }

  if (location.state) {
    currentCasesArray = location.state.currentCasesArray;
  }

  const handleScroll = () => {
    var logoColor = 'white';
    var sideMenuIsHidden = false;
    var sideMenuLogoColor = 'white';

    //TODO fix this spaghetti

    /**
     * Header reaches top of el
     * difference between el + space that it takes and top of viewport reaches 0
     **/

    //waypoints for header logo - control areas to check if el reaches top of viewport

    var caseClassificationElementWaypoint = makeScrollWaypoint (
      '.case-classification-wrapper'
    );

    var carousel360Waypoint = makeScrollWaypoint ('.carousel-360-container');

    var caseContentSolutionWaypoint = makeScrollWaypoint (
      '.case-content-solution'
    );

    var multimediaGalleryWaypoint = makeScrollWaypoint ('.multimedia-gallery');

    var caseContentWorkProcessWaypoint = makeScrollWaypoint (
      '.case-content-work-process'
    );

    var advantagesWaypoint = makeScrollWaypoint ('.advantages-block');

    var testimonialWaypoint = makeScrollWaypoint ('.testimonial');

    var caseFooterWaypoint = makeScrollWaypoint ('.case-footer');

    // setting logo color and hamburguer menu color with scroll

    if (caseClassificationElementWaypoint <= 0) {
      logoColor = '#0033a0';

      if (carousel360Waypoint <= 0) {
        logoColor = 'white';

        if (caseContentSolutionWaypoint <= 0) {
          logoColor = '#0033a0';

          if (multimediaGalleryWaypoint <= 0) {
            logoColor = 'white';

            if (caseContentWorkProcessWaypoint <= 0) {
              logoColor = '#0033a0';

              if (advantagesWaypoint <= 0) {
                logoColor = 'white';
              }
              if (testimonialWaypoint <= 0) {
                logoColor = '#0033a0';
              }
              if (caseFooterWaypoint <= 0) {
                logoColor = 'white';
              }
            }
          }
        }
      }
    }

    /**
     * Side menu in bottom reaches top of el
     *
     * difference between bottom - space element takes reaches 0
     *
     **/

    //waypoints for side menu logo

    var panoBannerWaypointBottom = makeScrollWaypoint ('.case-banner-wrapper');

    var carousel360WaypointBottom = makeScrollWaypointBottom (
      '.carousel-360-container'
    );

    var caseContentSolutionWaypointBottom = makeScrollWaypointBottom (
      '.case-content-solution'
    );

    var multimediaGalleryWaypointBottom = makeScrollWaypointBottom (
      '.multimedia-gallery'
    );

    var caseContentWorkProcessWaypointBottom = makeScrollWaypointBottom (
      '.case-content-work-process'
    );

    var advantagesWaypointBottom = makeScrollWaypointBottom (
      '.advantages-block'
    );

    var testimonialWaypointBottom = makeScrollWaypointBottom ('.testimonial');

    var caseFooterWaypointBottom = makeScrollWaypointBottom ('.case-footer');

    if (panoBannerWaypointBottom < -50) {
      sideMenuLogoColor = '#0033a0';
      sideMenuIsHidden = false;

      if (carousel360WaypointBottom <= 0) {
        sideMenuLogoColor = 'white';
      }
      if (caseContentSolutionWaypointBottom <= 0) {
        sideMenuLogoColor = '#0033a0';
        if (multimediaGalleryWaypointBottom <= 0) {
          sideMenuLogoColor = 'white';
          if (caseContentWorkProcessWaypointBottom <= 0) {
            sideMenuLogoColor = '#0033a0';
          }
          if (advantagesWaypointBottom <= 0) {
            sideMenuLogoColor = 'white';
          }
          if (testimonialWaypointBottom <= 0) {
            sideMenuLogoColor = '#0033a0';
          }
          if (caseFooterWaypointBottom <= 0) {
            sideMenuLogoColor = 'white';

            setHasReadTillEnd (true);
          }
        }
      }
    } else {
      sideMenuIsHidden = true;
    }

    setSideMenuLogoIsHidden (sideMenuIsHidden);
    setHeaderLogoColor (logoColor);
    setSideMenuLogoColor (sideMenuLogoColor);
  };

  useEffect (() => {
    window.addEventListener ('scroll', handleScroll);

    return () => {
      window.removeEventListener ('scroll', handleScroll);
    };
  });

  useEffect (
    () => {
      setHasReadTillEnd (false);
    },
    [fileFrontmatter.path]
  );

  useEffect (
    () => {
      if (hasReadTillEnd) {
        ReactGA.event ({
          category: 'Showcase',
          action: 'readFullContent',
        });
      }
    },
    [hasReadTillEnd]
  );

  return (
    <Layout
      path={fileFrontmatter.path}
      locale={locale}
      headerLogoColor={headerLogoColor}
    >
      <SEO
        title={`${fileFrontmatter.title} | ${website._title}`}
        pathname={fileFrontmatter.path}
        desc={fileFrontmatter.description}
        banner={fileFrontmatter.image.publicURL}
        showCase
      />
      <div className="case-container">
        <div
          className="case-banner-wrapper"
          onClick={() => setPanoInteraction (true)}
          onWheel={() => setScrollInteraction (true)}
          onTouchStart={
            typeof window !== `undefined` && isTouchDevice ()
              ? () => setScrollInteraction (true)
              : undefined
          }
        >
          {fileFrontmatter.panoBannerImage !== null
            ? <div>
                <PanoBanner
                  bannerXml={fileFrontmatter.panoBannerImage.panoImage}
                  locale={locale}
                  panoCarouselFolder={fileFrontmatter.panoCarouselFolder}
                  settingsPanoBannerFov={fileFrontmatter.panoBannerImage.fov}
                  settingsPanoBannerHlookat={
                    fileFrontmatter.panoBannerImage.hlookat
                  }
                  settingsPanoBannerVlookat={
                    fileFrontmatter.panoBannerImage.vlookat
                  }
                  settingsPanoBannerLimitView={
                    fileFrontmatter.panoBannerImage.limitView
                  }
                  scrollInteraction={scrollInteraction}
                  scrollInteractionSet={setScrollInteraction}
                />
                {fileFrontmatter.panoBannerImage !== null &&
                  <div className="case-krpano-interaction-logo-container">
                    <KrPanoInteractionLogo
                      panoInteraction={panoInteraction}
                      locale={locale}
                    />
                  </div>}
                <div className="case-banner-text-wrapper-">
                  <BannersSkin
                    title={fileFrontmatter.title}
                    productTypeShort={fileFrontmatter.productTypeShort}
                    segmentTag={fileFrontmatter.segmentTag}
                    areaTag={fileFrontmatter.areaTag}
                    solutionTag={fileFrontmatter.solutionTag}
                    productType={fileFrontmatter.productType}
                  />
                </div>
                <ScrollInteractionLogo
                  scrollInteraction={scrollInteraction}
                  scrollInteractionSet={setScrollInteraction}
                  locale={locale}
                />
              </div>
            : <StaticBanner
                image={fileFrontmatter.image}
                title={fileFrontmatter.title}
                productTypeShort={fileFrontmatter.productTypeShort}
                segmentTag={fileFrontmatter.segmentTag}
                areaTag={fileFrontmatter.areaTag}
                solutionTag={fileFrontmatter.solutionTag}
                productType={fileFrontmatter.productType}
                scrollInteraction={scrollInteraction}
                scrollInteractionSet={setScrollInteraction}
              />}
        </div>
        <div className="case-content-wrapper">
          <div className={'logo-menu ' + logoSideMenuIsHidden}>
            <LogoMenu
              logoMenuColor={sideMenuLogoColor}
              productTypeShort={fileFrontmatter.productTypeShort}
              locale={locale}
            />
          </div>
          <div className="case-classification-wrapper">
            <CaseClassification
              title={fileFrontmatter.title}
              segmentTag={fileFrontmatter.segmentTag}
              areaTag={fileFrontmatter.areaTag}
              solutionTag={fileFrontmatter.solutionTag}
              tags={fileFrontmatter.tags}
              productTypeShort={fileFrontmatter.productTypeShort}
              productType={fileFrontmatter.productType}
              client={fileFrontmatter.client}
              brochureMessage={fileFrontmatter.brochureMessage}
              brochureURL={fileFrontmatter.brochure.publicURL}
              readingTime={fileFrontmatter.readingTime}
            />
          </div>
          <div className="case-text-highlight-wrapper">
            <MainHighlight
              paddingTop={'130px'}
              image={fileFrontmatter.highlightImage}
              textContent={fileFrontmatter.caseContentMainHighlight}
            />
          </div>
          <div className="case-content-intro">
            <SimpleTextBlock
              paddingTop={'90px'}
              text={fileFrontmatter.caseContentIntro}
              textColor={'#333333'}
            />
          </div>
          <div className="case-content-opportunity">
            <SimpleTextBlock
              paddingTop={'130px'}
              text={fileFrontmatter.caseContentOpportunity}
              textColor={'#333333'}
              header={fileFrontmatter.caseContentOpportunityHeader}
            />
          </div>
          <div className="carousel-360-container">
            {fileFrontmatter.panoCarousel360
              ? <PanoCarousel360
                  panos={fileFrontmatter.panoCarousel360}
                  messageFor360Gallery={fileFrontmatter.messageFor360Gallery}
                  externalLink360Gallery={
                    fileFrontmatter.externalLink360Gallery
                  }
                  panoCarouselFolder={fileFrontmatter.panoCarouselFolder}
                />
              : <Carousel360
                  paddingTop={'130px'}
                  images={fileFrontmatter.gallery360}
                  messageFor360Gallery={fileFrontmatter.messageFor360Gallery}
                  externalLink360Gallery={
                    fileFrontmatter.externalLink360Gallery
                  }
                />}
          </div>

          <div className="case-content-solution">
            <SimpleTextBlock
              paddingTop={'130px'}
              text={fileFrontmatter.caseContentSolution}
              textColor={'#333333'}
              header={fileFrontmatter.caseContentSolutionHeader}
            />
          </div>
          <div className="multimedia-gallery">
            <MultimediaGallery
              paddingTop={'130px'}
              casePath={fileFrontmatter.path}
              data={fileFrontmatter.multimediaGallery}
            />
          </div>
          <div className="case-content-work-process" style={{paddingTop: 0}}>
            <SimpleTextBlock
              paddingTop={'130px'}
              text={fileFrontmatter.caseContentWorkProcess}
              textColor={'#333333'}
              header={fileFrontmatter.caseContentWorkProcessHeader}
            />
          </div>

          <div className="advantages-block">
            <AdvantagesBlock
              paddingTop={'130px'}
              header={fileFrontmatter.caseContentAdvantagesHeader}
              advantagesContent={fileFrontmatter.caseContentAdvantages}
              bgPicture={fileFrontmatter.caseContentAdvantagesBackGroundPicture}
            />
          </div>

          <div className="testimonial">
            <Testimonial
              paddingTop={'130px'}
              paddingBottom={'130px'}
              text={fileFrontmatter.caseContentRecommendation}
              name={fileFrontmatter.caseContentRecommendationName}
              role={fileFrontmatter.caseContentRecommendationRole}
            />

          </div>

          <div className="case-footer">
            <CaseFooter
              locale={locale}
              productTypeShort={fileFrontmatter.productTypeShort}
              segmentTag={fileFrontmatter.segmentTag}
              solutionTag={fileFrontmatter.solutionTag}
              path={fileFrontmatter.path}
              title={fileFrontmatter.title}
              previousPath={previousPath}
              currentCasesArray={currentCasesArray}
              previousPathForPage={previousPathForPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Template($locale: String, $folderName: String) {
    file(name: { eq: $locale }, relativeDirectory: { eq: $folderName }) {
      childMarkdownRemark {
        frontmatter {
          title
          path
          description
          panoBannerImage{
            panoImage
            hlookat
            vlookat
            fov
            limitView
          }
          segmentTag
          readingTime
          brochureMessage
          brochure{
            publicURL
          }
          panoCarouselFolder
          panoCarousel360{
            panoImage
            hlookat
            vlookat
            fov
            limitView
          }
          areaTag
          solutionTag
          tags
          productTypeShort
          productType
          client
          clientURL
          caseContentMainHighlight
          caseContentIntro
          caseContentOpportunity
          caseContentOpportunityHeader
          messageFor360Gallery
          externalLink360Gallery
          caseContentSolutionHeader
          caseContentSolution
          caseContentRecommendation
          caseContentRecommendationName
          caseContentRecommendationRole
          caseContentWorkProcessHeader
          caseContentWorkProcess    
          caseContentAdvantagesHeader
          public
          gallery360 {
            oracleMessage {
              firstLine
              secondLine
            }
            linkPhoto {
              publicURL
              childImageSharp {
                fluid(maxHeight: 535) {
                  ...GatsbyImageSharpFluid
                }
              }
            }            
          }
          multimediaGallery {
            type
            videoId{
              publicURL
            }
            oracleMessage{
              firstLine
              secondLine
            }
            
  linkPhoto {
              publicURL
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            sliderBgImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          caseContentAdvantages {
            text
            logo
          }
         
          highlightImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          } 
          caseContentAdvantagesBackGroundPicture {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

/*




  <div className="case-content-work-process">
          <LongTextBlock
            header={fileFrontmatter.caseContentWorkProcessHeader}
            text={fileFrontmatter.caseContentWorkProcess}
            textColor={'#333333'}
          />
        </div>








         multimediaGallery {
            type
            linkVideo
            linkPhoto {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1170, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            sliderBgImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1170, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }




    <MultimediaGallery
              paddingTop={'130px'}
              data={fileFrontmatter.multimediaGallery}
            />

*/
